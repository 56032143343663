import { Component, h, render } from 'preact';
import IScroll from '../vendor/iscroll_zoom';
import { isIOS, isAndroid, isWP } from "../utils/deviceDetection";

export default class ImageZoom extends Component {
	constructor(props) {
		super(props);

		this.state = {
			imageSrc: '',
			imgType: '',
			screenType: '',
			loading: true,
			IScrollInitd: false,
			currZoom: 1,
			minZoom: 1,
			maxZoom: 4,
			dragging: false,
			previousHeight: 0,
			previosuWidth: 0,
			imgStyle: {},
			textVisible: true,
		};

		//Ensure proper context
		this.zoomActivate = this.zoomActivate.bind(this);
		this.zoom = this.zoom.bind(this);
		this.shrink = this.shrink.bind(this);
		this.enlarge = this.enlarge.bind(this);
		this.ratio = this.ratio.bind(this);
		this.zoomInit = this.zoomInit.bind(this);
		this.toggleText = this.toggleText.bind(this);
		this.iScrollZoom = this.iScrollZoom.bind(this);

		this.determinedOffsets = false;
		this.centerOffset = {
			x: 0,
			y: 0,
		};

		//Initialize orientation handler
		window.addEventListener('orientationchange', () => {
			setTimeout(() => {
				this.zoomInit();

				this.Scroll.refresh();
			}, 0);
		});

		//Initialize resize handler
		let isResizing = false,
			lastResize = null,
			AnimationFrameID;

		const reInitZoom = () => {
			//Calculate zoom and image scaling
			this.zoomInit();
			this.calculateMargins();

			setTimeout(() => {
				this.Scroll.refresh();
			}, 0);

			//End animationFrame if it has been over 300ms since last resize
			if ((performance.now() - lastResize) > 300) {
				cancelAnimationFrame(AnimationFrameID);
				isResizing = false;
				return;
			}

			AnimationFrameID = requestAnimationFrame(reInitZoom);
		}

		const resizeHandler = () => {
			if (!isResizing) {
				AnimationFrameID = requestAnimationFrame(reInitZoom);
			}
			isResizing = true;
			lastResize = performance.now();
		}

		window.addEventListener('resize', resizeHandler);

		this.zoomMouse = this.zoom.bind(null, 'mouse');
		this.zoomTouch = this.zoom.bind(null, 'touch');
		this.zoomDeactivate = this.zoomDeactivate.bind(this);
	}

	componentWillMount() {
		//Determine screenType
		if (window.devicePixelRatio < 1.5) {
			this.setState({ screenType: 'sd' });
		} else if (window.devicePixelRatio < 2.5) {
			this.setState({ screenType: 'hd' });
		} else {
			this.setState({ screenType: 'xhd' });
		}

		//Determine imgType
		if (typeof this.props.imgSrc == 'string') {
			this.setState({ imgType: 'unknown', imageSrc: this.props.imgSrc });
		} else {
			//Determiner for xhd screentype
			if (this.state.screenType === 'xhd') {
				if (this.props.imgSrc.xhd) {
					this.setState({ imgType: 'xhd', imageSrc: this.props.imgSrc.xhd });
				} else if (this.props.imgSrc.hd) {
					this.setState({ imgType: 'hd', imageSrc: this.props.imgSrc.hd });
				} else if (this.props.imgSrc.sd) {
					this.setState({ imgType: 'sd', imageSrc: this.props.imgSrc.sd });
				}
			} else if (this.state.screenType === 'hd') {
				//Determiner for hd screentype
				if (this.props.imgSrc.hd) {
					this.setState({ imgType: 'hd', imageSrc: this.props.imgSrc.hd });
				} else if (this.props.imgSrc.sd) {
					this.setState({ imgType: 'sd', imageSrc: this.props.imgSrc.sd });
				}
			} else if (this.state.screenType === 'sd') {
				//Determiner for sd screentype
				this.setState({ imgType: 'sd', imageSrc: this.props.imgSrc.sd });
			}
		}
	}

	componentWillUnmount() {
		this.Scroll.destroy();
		this.Scroll = null;
	}

	componentDidMount() {
		//add eventListener for image load
		if (this.imageZoomNode.complete) {
			this.setState({ loading: false });
		} else {
			this.imageZoomNode.addEventListener('load', () => { this.setState({ loading: false }) });
		}

		//Initialize IScroll
		this.Scroll = new IScroll(this.scrollerNode, {
			zoom: true,
			scrollX: true,
			scrollY: true,
			freeScroll: true,
			useTransition: false,
			scrollbars: true,
			fadeScrollbars: true,
			scrollbarsWrapper: this.props.node,
			mouseWheel: true,
			bounce: false,
		});

		//Initialize zoom-tracking
		this.Scroll.on('zoomEnd', () => {
			this.setState({ currZoom: this.scale });
		});

		// Add event listener when user pinches to zoom
		this.imageZoomNode.addEventListener('touchmove', (evt) => {
			requestAnimationFrame(() => {
				this.calculateMargins();
				this.setState({ currZoom: this.Scroll.scale });
			});
		});
	}

	componentDidUpdate() {
		//If image is loaded and zoom-values have not been initialized yet, then do it once now.
		if (!this.state.IScrollInitd && !this.state.loading) {
			this.zoomInit();
			this.Scroll.refresh();

			// Calculate inital margins
			this.calculateMargins();
			setTimeout(() => {
				this.calculateMargins();
				this.Scroll.refresh();
			});

			this.setState({ IScrollInitd: true });

			//Add class display:none to throbber
			setTimeout(() => {
				this.throbberNode.style.display = 'none';
			}, 500);
		}
	}

	render() {
		return (
			<div class={"image-zoom__container " + ((isIOS || isAndroid || isWP) ? "image-zoom__container--isTouchOS" : "image-zoom__container--isMouseOS")}>
				<div class={this.state.loading ? "image-zoom loading" : "image-zoom"} onwheel={this.mouseWheelZoom}>
					<div class="image-zoom__scroller-wrapper" ref={(node) => { this.scrollerWrapperNode = node || this.scrollerWrapperNode; }}>
						<div class="image-zoom__scroller" ref={(node) => { this.scrollerNode = node || this.scrollerNode; }}>
							<img class="image-zoom__image" ref={(node) => { this.imageZoomNode = node || this.imageZoomNode }} src={this.state.imageSrc} style={this.state.imgStyle} />
						</div>
					</div>
					{this.props.text &&
						<div
							class={`
								image-zoom__panel
								${(this.state.textVisible ? "image-zoom__panel--isVisible" : "")}
								${(this.state.maxZoom !== 1 ? "" : "image-zoom__panel--noRightPadding")}
							`}
						>
							<div class="image-zoom__text">
								<span
									class={"image-zoom__text__close " + (this.state.textVisible ? "cross" : "arrow")}
									onClick={this.toggleText}
								/>
								<span dangerouslySetInnerHTML={{ __html: this.props.text }}></span>
							</div>
						</div>
					}
					<div class={this.state.maxZoom !== 1 ? "image-zoom__tools visible" : "image-zoom__tools"}>
						<div id="ratio-tool" class="image-zoom__tools__zoom-button small ratio" onClick={this.ratio}><span class="ratio__text">1:1</span></div>
						<div id="shrink-tool" class="image-zoom__tools__zoom-button shrink" onClick={this.shrink}><i className="gImageZoom-icon-zoom_out" /></div>
						<div class="image-zoom__tools__zoom-group">
							<span id="zoom-indicator" class="image-zoom__tools__zoom-group__indicator" style={`left:${Math.round((this.state.currZoom - 1) / (this.state.maxZoom - 1) * 96 * 10) / 10}px;`}>{Math.round(this.state.currZoom / this.state.maxZoom * 100)}%</span>
							<div
								id="zoom-tool"
								class="image-zoom__tools__zoom-group__track"
								ref={(node) => { this.zoomToolTrack = node || this.zoomToolTrack; }}
								onMouseDown={this.zoomActivate.bind(null, 'mouse')}
								onTouchStart={this.zoomActivate.bind(null, 'touch')}
								style={`background:-webkit-gradient(linear, left top, right top, color-stop(0%,#dc4320), color-stop(${this.calcCurrRange()}%, #dc4320), color-stop(${this.calcCurrRange()}%, rgba(0,0,0,0)));background:linear-gradient(to right, #dc4320 0%, #dc4320 ${this.calcCurrRange()}%, rgba(0,0,0,0) ${this.calcCurrRange()}%`}
							>
								<div
									class="image-zoom__tools__zoom-group__thumb"
									ref={(node) => { this.zoomToolThumb = node || this.zoomToolThumb; }}
									style={`left:${Math.round((this.state.currZoom - 1) / (this.state.maxZoom - 1) * 96 * 10) / 10}px`}
								>
								</div>
							</div>
						</div>
						<div id="enlarge-tool" class="image-zoom__tools__zoom-button enlarge" onClick={this.enlarge}><i className="gImageZoom-icon-zoom_in" /></div>
					</div>
				</div>
				<div ref={(node) => { this.throbberNode = node || this.throbberNode }} class={this.state.loading ? "image-zoom__throbber loading" : "image-zoom__throbber"}></div>
			</div>
		);
	}

	// Function to toggle the text panel in and out of view
	toggleText() {
		this.setState({ textVisible: !this.state.textVisible });
	}

	zoomInit() {
		//get ratio for maxZoom
		var ratioNode = this.props.node.clientWidth / this.props.node.clientHeight;
		var ratioImg = this.imageZoomNode.naturalWidth / this.imageZoomNode.naturalHeight;

		if (ratioNode < ratioImg) {
			//Scale image to fit horizontally
			var ratio = this.imageZoomNode.naturalWidth / this.props.node.clientWidth;
		} else {
			//Scale image to fit vertically
			var ratio = this.imageZoomNode.naturalHeight / this.props.node.clientHeight;
		}

		//Setup maxZoom
		switch (this.state.screenType) {
			case 'sd':
				switch (this.state.imgType) {
					case 'sd':
						this.setState({ maxZoom: ratio });
						break;

					default:
						this.setState({ maxZoom: ratio });
				}
				break;

			case 'hd':
				switch (this.state.imgType) {
					case 'sd':
						this.setState({ maxZoom: ratio * 0.75 });
						break;

					case 'hd':
						this.setState({ maxZoom: ratio * 0.5 });
						break;

					default:
						this.setState({ maxZoom: ratio * 0.75 });
				}
				break;

			case 'xhd':
				switch (this.state.imgType) {
					case 'sd':
						this.setState({ maxZoom: ratio * 0.75 });
						break;

					case 'hd':
						this.setState({ maxZoom: ratio * 0.5 });
						break;

					case 'xhd':
						this.setState({ maxZoom: ratio * 0.33 });
						break;

					default:
						this.setState({ maxZoom: ratio * 0.75 });
				}
				break;

			default:
			// No other screenTypes will exist
		}

		//if image is smaller than node, force image to scale to node
		if (this.state.maxZoom <= 1.1) {
			this.setState({ maxZoom: 1 });
		}

		//onResize make sure that currZoom is not greater than maxZoom
		if (this.state.currZoom > this.state.maxZoom) {
			this.setState({ currZoom: this.state.maxZoom });
			this.iScrollZoom(this.state.currZoom);
		}

		//Pass zoom information to IScroll
		this.Scroll.options.zoomMin = this.state.minZoom;
		this.Scroll.options.zoomMax = this.state.maxZoom;

		//Get proper position of zoomThumb
		var trackStart = this.zoomToolTrack.getBoundingClientRect().left;
		this.setState({ trackStart: trackStart, trackEnd: trackStart + 96 });
	}

	// Calculate margins to center image
	calculateMargins() {
		// Contains the styles to be updated
		let style = {};

		// Get current image dimensions
		const boundingRect = this.imageZoomNode.getBoundingClientRect();
		let currImageHeight = boundingRect.height;
		let currImageWidth = boundingRect.width;
		const naturalWidth = this.imageZoomNode.naturalWidth;
		const naturalHeight = this.imageZoomNode.naturalHeight;

		// Define wrapper dimensions
		let nodeHeight = this.props.node.clientHeight;
		let nodeWidth = this.props.node.clientWidth;

		// Determine ratio between wrapper and image
		var ratioNode = nodeWidth / nodeHeight;
		var ratioImg = this.imageZoomNode.naturalWidth / this.imageZoomNode.naturalHeight;

		// If image is smaller than node in both dimension, make sure to center
		// it on both axis
		if (nodeHeight > naturalHeight && nodeWidth > naturalWidth) {
			const marginHorizontal = (nodeWidth - naturalWidth) / 2;
			const marginVertical = (nodeHeight - naturalHeight) / 2;

			style.left = style.right = `${marginHorizontal}px`;
			style.top = style.bottom = `${marginVertical}px`;
		} else {
			// Else, determine position based on ratio between node and img
			if (ratioNode < ratioImg) {
				// setup vertical styling
				style.width = '100%';
				style.left = null;
				style.right = null;

				// Apply margins vertically
				let marginSize = (nodeHeight - currImageHeight) / 2;

				// No margin should be applied when image is larger than wrapper
				if (marginSize > 0) {
					style.top = `${marginSize}px`;
					style.bottom = `${marginSize}px`;
				}
			} else {
				// Setup horizontal styling
				style.width = null;
				style.top = null;
				style.bottom = null;

				// Apply margins horizontally
				let marginSize = (nodeWidth - currImageWidth) / 2;

				if (marginSize > 0) {
					style.left = `${marginSize}px`;
					style.right = `${marginSize}px`;
				}
			}
		}

		// Update state!
		this.setState({ previousWidth: currImageWidth, previousHeight: currImageHeight, imgStyle: style });
	}

	//TOOLS
	zoomActivate(type, evt) {
		this.setState({ dragging: true });
		this.zoom(type, evt);

		document.body.addEventListener('mousemove', this.zoomMouse);
		document.body.addEventListener('touchmove', this.zoomTouch);
		document.body.addEventListener('mouseup', this.zoomDeactivate);
		document.body.addEventListener('touchend', this.zoomDeactivate);
		document.body.addEventListener('mouseleave', this.zoomDeactivate);

		evt.preventDefault();
	}

	zoomDeactivate = () => {
		if (this.state.dragging) {
			this.setState({ dragging: false });
		}

		document.body.removeEventListener('mouseup', this.zoomDeactivate);
		document.body.removeEventListener('touchend', this.zoomDeactivate);
		document.body.removeEventListener('mouseleave', this.zoomDeactivate);
		document.body.removeEventListener('mousemove', this.zoomMouse);
		document.body.removeEventListener('touchmove', this.zoomTouch);
	}

	zoom(type, e) {
		if (this.state.dragging) {
			var currPos = 0;
			switch (type) {
				case 'mouse':
					currPos = e.clientX;
					break;

				case 'touch':
					currPos = e.changedTouches[0].pageX;
					break;

				default:
				//No other types of input will be used
			}

			var position = Math.round(currPos - this.state.trackStart);
			if (position < 0) {
				position = 0;
			} else if (position > 96) {
				position = 96;
			}

			var currZoom = (position / 96 * (this.state.maxZoom - 1)) + 1;

			this.setState({ currZoom: currZoom });
			this.iScrollZoom(this.state.currZoom);

			e.preventDefault();
		}
	}

	calcCurrRange() {
		let position = (this.state.currZoom - this.state.minZoom) / (this.state.maxZoom - this.state.minZoom) * 100;

		return Math.ceil(position);
	}

	shrink() {
		//Zoom out 20%
		let newZoom = this.state.currZoom - ((this.state.maxZoom - this.state.minZoom) / 5);

		if (newZoom < this.state.minZoom) {
			newZoom = this.state.minZoom;
		}

		this.animateScale(newZoom, 300);
	}

	enlarge() {
		//Zoom in 20%
		let newZoom = this.state.currZoom + ((this.state.maxZoom - this.state.minZoom) / 5);

		if (newZoom > this.state.maxZoom) {
			newZoom = this.state.maxZoom;
		}

		this.animateScale(newZoom, 300);
	}

	ratio() {
		this.animateScale(this.state.minZoom, 500);
	}

	iScrollZoom(zoom) {
		this.getParentOffsets();
		const wrapperRect = this.props.node.getBoundingClientRect();
		const centerX = (wrapperRect.right - wrapperRect.left) / 2 + wrapperRect.left;
		const centerY = (wrapperRect.bottom - wrapperRect.top) / 2 + wrapperRect.top;
		this.Scroll.zoom(zoom, centerX + this.centerOffset.x, centerY + this.centerOffset.y, 0);
		this.calculateMargins.call(this);
	}

	// Helper to animate from one scale to another
	animateScale(newZoom, duration) {
		let startTime = performance.now();
		let startZoom = this.state.currZoom;

		animateStep.call(this);

		function animateStep() {
			let now = performance.now() - startTime;
			if (now < duration) {
				// Calculate zoomLevel
				let zoomLevel = (newZoom - startZoom) * inOutQuart(now / duration);
				let currZoom = zoomLevel + startZoom;

				// Apply zoomlevel
				this.setState({ currZoom });
				this.iScrollZoom(currZoom);

				requestAnimationFrame(animateStep.bind(this));
			} else {
				// Finishing animation
				this.setState({ currZoom: newZoom });
				this.iScrollZoom(newZoom);
			}
		}

		function inOutQuart(t) {
			let easing = t < .5 ?
				2 * t * t :
				-1 + (4 - 2 * t) * t;

			return easing;
		}
	}

	/**
	 * Due to iScroll implementation then computing center coordinates of our 
	 * container needs to be offset by the amount parent elements have
	 * been translated using transform: translate() in order to determine the
	 * correct center point.
	 */
	getParentOffsets() {
		if (this.determinedOffsets) {
			return;
		}

		let currentParent = this.props.node.parentElement;
		let unscaledLeft = 0;
		let unscaledTop = 0;

		const boundingRect = currentParent.getBoundingClientRect();
		let absoluteLeft = boundingRect.left;
		let absoluteTop = boundingRect.top;

		while (currentParent != null) {
			absoluteLeft += currentParent.scrollLeft;
			absoluteTop += currentParent.scrollTop;
			unscaledLeft += currentParent.offsetLeft;
			unscaledTop += currentParent.offsetTop;

			currentParent = currentParent.parentElement;
		}

		this.centerOffset.x = unscaledLeft - absoluteLeft;
		this.centerOffset.y = unscaledTop - absoluteTop;

		this.determinedOffsets = true;
	}
}
