exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./icomoon.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./icomoon.eot") + "?#iefix");
var ___CSS_LOADER_URL___2___ = urlEscape(require("./icomoon.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./icomoon.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./icomoon.ttf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./icomoon.svg") + "#icomoonregular");

// Module
exports.push([module.id, "@font-face {\n    font-family: 'gImageZoomIcons';\n    src: url(" + ___CSS_LOADER_URL___0___ + ");\n    src: url(" + ___CSS_LOADER_URL___1___ + ") format('embedded-opentype'),\n         url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___3___ + ") format('woff'),\n         url(" + ___CSS_LOADER_URL___4___ + ") format('truetype'),\n         url(" + ___CSS_LOADER_URL___5___ + ") format('svg');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n[class^=\"gImageZoom-icon-\"], [class*=\" gImageZoom-icon-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'gImageZoomIcons' !important;\n  speak: none;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.gImageZoom-icon-zoom_in:before {\n  content: \"\\e900\";\n}\n.gImageZoom-icon-zoom_out:before {\n  content: \"\\e901\";\n}\n", ""]);

