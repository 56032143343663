import { h, render } from 'preact';
import ImageZoom from './components/image_zoom';

// Load stylesheet!
import 'sass/image-zoom.scss';

window.gImageZoom = function gImageZoom(args){
	var wrapper = document.createElement('div');
	wrapper.className = 'image-zoom__wrapper';
	args.node.appendChild(wrapper);

	render(<ImageZoom imgSrc={args.src} node={wrapper} text={args.text ? args.text : undefined} />, wrapper);
};
